import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store


import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData,
  Fn_ChangeStateValue
} from "../../store/functions";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert"

import {
  AvForm,
  AvField,AvRadioGroup,AvRadio
} from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import './datatables.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { API_HELPER } from "helpers/ApiHelper";

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
class pageList_MemberUpdateRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      formData : {},
      success_msg: false,
      dynamic_title: "",
      dynamic_description: "",
      company : [],
      page: 1,
      sizePerPage: 10,
      productData: [],
      RReason : '',
      F_RetailerComission : 0,
      F_DistributorComission : 0,
      F_SDistributorComission : 0,
      F_MDistributorComission : 0,
      Charges : 0,
      commissionmodal : false,
      success_dlg : false,
      confirm_undorefund : false,
      title : '',
      UTR : ''

    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Member Update Request";
    this.modalTitle = "Member Update Request";
    this.rtPage_Add = "add-cashwithdrawl";
    this.rtPage_Edit = "edit-customermaster";
    this.API_URL = "Masters/0/token/MemberUpdateList";
    

    //Event Binding
    
   
  
    this.syno  =  this.syno.bind(this);
    this.edit  =  this.edit.bind(this);
    this.accept  = this.accept.bind(this);
    this.reject  = this.reject.bind(this);
    
    
  }

 


  edit (Id){

    //const foundItem = this.state.productData.find(item => item.Id === Id);

    this.setState ({
     WithdrawId  : Id,
     commissionmodal: true
    });

  }





  componentDidMount() {
    Fn_FillListData(this.obj, "productData", this.API_URL + "/Id/0");
  }



  syno(){
    this.setState({success_msg : false, success_dlg : false , commissionmodal : false})
  }



 


accept() {

}

reject () {

}












  
  render() {

    const obj = JSON.parse(sessionStorage.getItem("authUser"));


    const columns = [{
      dataField: 'DateOfCreation',
      text: 'Date/Time',
      sort: true,
    }, {
      dataField: 'FirstName',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'MembershipNo',
      text: 'MembershipNo',
      sort: true,
     
    },
     {
      dataField: 'MobileNo_SMS',
      text: 'MobileNo',
      sort: true,
     
    },
     {
      dataField: 'Email',
      text: 'Email',
      sort: true,
     
    },
     {
      dataField: 'STATUS',
      text: 'STATUS',
      sort: true,
     
    },
   
     {
      dataField: "Action",
      text: "Approve",
      formatter: (cellContent, row) => {
        return (
          <button
          type="button"
            className="btn btn-success btn-xs"
            onClick={() => this.edit(row.Id)}
          >
            Proceed
          </button>
        );
      }
    } 
  
  ];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: this.state.productData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (this.state.productData).length }];


  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;

    return (




     <>

<React.Fragment>
        <div className="page-content" style={{marginTop: 150}}>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.breadCrumbTitle}
              breadcrumbItem={this.breadCrumbItem}
            />
                         <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                     // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
              </Row>





                         
                         </Container>

                         

                      <Modal
                        isOpen={this.state.commissionmodal}
                        toggle={this.commissionmodal}
                        scrollable={true}
                        backdrop={'static'}
                        id="staticBackdrop2"
                      >
                        <div className="modal-header">
                          <h5 className="modal-title" >Withdrawal Request</h5>
                          <button type="button" className="btn-close" onClick={() =>
                            this.setState({ commissionmodal: false })
                          } aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        <Row>
                        <Col sm="4" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Remarks</label>
                                        </Col>
                                        <Col sm="6" className="mb-3">
                          <textarea cols={4} name="Remarks" label="Remarks" value={this.state.Remarks}  onChange={(e) => Fn_ChangeStateValue(this.obj, 'Remarks', e.target.value)}  placeholder="Enter Remarks"   type="text"  className="form-control" />
                         </Col>
                         </Row>
                        </div>
                        <div className="modal-footer">
                          
                          <button type="button" onClick={()=> this.setState({confirm_alert : true})} className="btn btn-danger">Reject</button>
                          <button type="button" onClick={()=> this.setState({confirm_undorefund : true})} className="btn btn-info">Accept</button>
                         
                        </div>
                      </Modal>


                         {this.state.confirm_alert ? (
                                                                <SweetAlert
                                                                    title="Are you sure to Reject Request?"
                                                                    warning
                                                                    showCancel
                                                                    confirmBtnText="Yes, Reject it!"
                                                                    confirmBtnBsStyle="success"
                                                                    cancelBtnBsStyle="danger"
                                                                     onConfirm={() =>this.reject()}
                                                                     onCancel={() =>this.setState({confirm_alert : false , Id : 0})}
                                                                >
                                                                 
                                                                </SweetAlert>
                                                                ) : null}



{this.state.confirm_undorefund ? (
                                                                <SweetAlert
                                                                    title="Are you sure to Accept Request?"
                                                                    warning
                                                                    showCancel
                                                                    confirmBtnText="Yes, Accept it!"
                                                                    confirmBtnBsStyle="success"
                                                                    cancelBtnBsStyle="danger"
                                                                     onConfirm={() =>this.accept()}
                                                                     onCancel={() =>this.setState({confirm_undorefund : false, Id : 0})}
                                                                >
                                                                  The Amount will be deduct!                                                                    
                                                                </SweetAlert>
                                                                ) : null}


{this.state.success_msg == true ? (
                      <SweetAlert
                        title={'Updated Successful!'}
                        success
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                      
                      </SweetAlert>
                    ) : null}


                         </div>
                         </React.Fragment>



  </>
    );
  }
}
export default compose(container)(pageList_MemberUpdateRequest);
